<template>
  <component-frame title="Feedback">
    <div class="sg-page__heading">
      <div class="on-this-page">
        <div class="on-this-page-title">On This Page</div>
        <ul class="on-this-page-anchor-list">
          <li><a href="#" v-scroll-to="'#alerts'">Alerts</a></li>
          <li>
            <a href="#" v-scroll-to="'#dismissable'">Dismissable Alert</a>
          </li>
          <li><a href="#" v-scroll-to="'#badges'">Badges</a></li>
          <li><a href="#" v-scroll-to="'#toast-message'">Toast Message</a></li>
          <li><a href="#" v-scroll-to="'#disclaimer'">Disclaimer Line</a></li>
        </ul>
      </div>
    </div>

    <h3 id="alerts">Alerts</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <div class="alert alert-success" role="alert">
            <div class="alert-icon">
              <svg class="icon icon-check">
                <use xlink:href="/icons/symbol-defs.svg#icon-check"></use>
              </svg>
            </div>
            <strong>Success alert.</strong> For delivering a positive message.
          </div>
          <div class="alert alert-info" role="alert">
            <div class="alert-icon">
              <svg class="icon icon-info">
                <use xlink:href="/icons/symbol-defs.svg#icon-info"></use>
              </svg>
            </div>
            <strong>Neutral alert/tip.</strong> Used for notes and tips
          </div>
          <div class="alert alert-warning" role="alert">
            <div class="alert-icon">
              <svg class="icon icon-warning">
                <use xlink:href="/icons/symbol-defs.svg#icon-warning"></use>
              </svg>
            </div>
            <strong>Warning alert.</strong> Tells user to be cautious when performing a task.
          </div>
          <div class="alert alert-danger" role="alert">
            <div class="alert-icon">
              <svg class="icon icon-error">
                <use xlink:href="/icons/symbol-defs.svg#icon-error"></use>
              </svg>
            </div>
            <strong>Error alert.</strong> For when a task has gone wrong.
          </div>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>
            Show Code
          </div>
          <pre v-highlight><code class="language-html">
      &lt;div class="alert alert-success" role="alert"&gt;
        &lt;div class="alert-icon"&gt;
          &lt;svg class="icon icon-check_circle"&gt;&lt;use xlink:href="symbol-defs.svg#icon-check_circle"&gt;&lt;/use&gt;&lt;/svg&gt;
        &lt;/div&gt;
        &lt;strong&gt;Success alert.&lt;/strong&gt; For delivering a positive message.
      &lt;/div&gt;
      &lt;div class="alert alert-info" role="alert"&gt;
        &lt;div class="alert-icon"&gt;
          &lt;svg class="icon icon-info"&gt;&lt;use xlink:href="symbol-defs.svg#icon-info"&gt;&lt;/use&gt;&lt;/svg&gt;
        &lt;/div&gt;
        &lt;strong&gt;Neutral alert/tip.&lt;/strong&gt; Used for notes and tips
      &lt;/div&gt;
      &lt;div class="alert alert-warning" role="alert"&gt;
        &lt;div class="alert-icon"&gt;
          &lt;svg class="icon icon-warning"&gt;&lt;use xlink:href="symbol-defs.svg#icon-warning"&gt;&lt;/use&gt;&lt;/svg&gt;
        &lt;/div&gt;
        &lt;strong&gt;Warning alert.&lt;/strong&gt; Tells user to be cautious when performing a task.
      &lt;/div&gt;
      &lt;div class="alert alert-danger" role="alert"&gt;
        &lt;div class="alert-icon"&gt;
          &lt;svg class="icon icon-error"&gt;&lt;use xlink:href="symbol-defs.svg#icon-error"&gt;&lt;/use&gt;&lt;/svg&gt;
        &lt;/div&gt;
        &lt;strong&gt;Error alert.&lt;/strong&gt; For when a task has gone wrong.
      &lt;/div&gt;
                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide">
        <ul>
          <li>
            Use success and error alerts primarily for dynamic form validation
          </li>
          <li>
            If an alert requires user action, provide a descriptive link that will scroll to the input error
          </li>
          <li>
            Use Neutral and warning alerts to direct attention to helpful tips and disclaimers
          </li>
        </ul>
      </div>
    </div>

    <h3 id="dismissable">Dismissable Alert</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <div class="alert alert-danger alert-dismissible fade show" role="alert">
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true"
                ><svg class="icon icon-close">
                  <use xlink:href="/icons/symbol-defs.svg#icon-close"></use></svg
              ></span>
            </button>
            <div class="alert-icon">
              <svg class="icon icon-error">
                <use xlink:href="/icons/symbol-defs.svg#icon-error"></use>
              </svg>
            </div>
            <strong>Oh snap!</strong> Change a few things up and try submitting again.
            <a href="#" class="alert-link">Find out what might be causing this error</a>
          </div>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>
            Show Code
          </div>
          <pre v-highlight><code class="language-html">
      &lt;div class="alert alert-danger" role="alert"&gt;
        &lt;button type="button" class="close" data-dismiss="alert" aria-label="Close"&gt;
          &lt;span aria-hidden="true"&gt;&times;&lt;/span&gt;
        &lt;/button&gt;
        &lt;div class="alert-icon"&gt;
          &lt;svg class="icon icon-error"&gt;&lt;use xlink:href="symbol-defs.svg#icon-error"&gt;&lt;/use&gt;&lt;/svg&gt;
        &lt;/div&gt;
        &lt;strong&gt;Oh snap!&lt;/strong&gt; Change a few things up and try submitting again.
        &lt;a href="#" class="alert-link"&gt;Find out what might be causing this error&lt;/a&gt;
      &lt;/div&gt;
                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide">
        <ul>
          <li>
            Use dismissable alerts for dynamic content or functionality. Good for expiration notices, validation errors etc
          </li>
        </ul>
      </div>
    </div>

    <h3 id="dismissable">Using alerts as callouts</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <div class="alert alert-info" role="alert">
            <div class="alert-icon">
              <svg class="icon icon-info">
                <use xlink:href="/icons/symbol-defs.svg#icon-info"></use>
              </svg>
            </div>
            <h4>
              IMPORTANT – This portion of the return must be signed by all transferees.
            </h4>

            <p class="mb-0">
              I certify and declare that the information given in this return is complete and correct in all respects. I acknowledge that there are penalties
              for tax avoidance or providing false information. These penalties may include double the tax, tax plus interest and a fine and/or up to two years
              imprisonment.
            </p>
          </div>

          <div class="alert alert-primary bg-transparent py-0 pl-3" role="alert">
            Complete this section only if you are applying for the first time home buyers’ exemption.
          </div>

          <div class="alert alert-primary bg-transparent py-0" role="alert">
            <div class="alert-icon">
              <svg class="icon icon-legal">
                <use xlink:href="/icons/symbol-defs.svg#icon-legal"></use>
              </svg>
            </div>
            <strong>Freedom of Information and Protection of Privacy Act (FOIPPA)</strong>
            – The personal information on this form is collected for the purpose of administering the Property Transfer Tax Act under the authority of section
            26(a) of the FOIPPA. Questions about the collection of this information can be directed to the Director, Property Transfer Tax, PO Box 9427 Stn Prov
            Govt, Victoria, BC V8W 9V1 (telephone: Victoria at 250-387-0555 or toll-free at 1 888 355-2700)
          </div>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>
            Show Code
          </div>
          <pre v-highlight><code class="language-html">

                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide">
        <ul>
          <li>
            Alerts can also be used to emphasize important text on a page and alert users to this content
          </li>
          <li>
            Combine alerts with utility classes to create variations that are less prominent
          </li>
          <li>
            Custom icons can also be used in context to help guide the user to better understand what type of content they are reading
          </li>
        </ul>
      </div>
    </div>

    <h3 id="badges">Badges</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <p>Success badge <span class="badge badge-pill badge-success">4</span></p>
          <p>Warning badge <span class="badge badge-pill badge-warning">2</span></p>
          <p>Error badge <span class="badge badge-pill badge-danger">1</span></p>
          <p>Info badge <span class="badge badge-pill badge-info">3</span></p>
          <p>Dark badge <span class="badge badge-pill badge-dark">12</span></p>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>
            Show Code
          </div>
          <pre v-highlight><code class="language-html">
&lt;p&gt;Success badge &lt;span class="badge badge-pill badge-success"&gt;4&lt;/span&gt;&lt;/p&gt;
&lt;p&gt;Warning badge &lt;span class="badge badge-pill badge-warning"&gt;2&lt;/span&gt;&lt;/p&gt;
&lt;p&gt;Error badge &lt;span class="badge badge-pill badge-danger"&gt;1&lt;/span&gt;&lt;/p&gt;
&lt;p&gt;Info badge &lt;span class="badge badge-pill badge-info"&gt;3&lt;/span&gt;&lt;/p&gt;
&lt;p&gt;Dark badge &lt;span class="badge badge-pill badge-dark"&gt;12&lt;/span&gt;&lt;/p&gt;
                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide">
        <ul>
          <li>Directs attention to a numerical value of an element</li>
        </ul>
      </div>
    </div>

    <h3 id="toast-message">Toast message</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <button class="btn btn-secondary" @click="toggleTopToast()">
            Toggle top toast
          </button>
          <button class="btn btn-secondary" @click="toggleBottomToast()">
            Toggle bottom toast
          </button>

          <transition name="fade">
            <div class="toast toast-top toast-info" role="alert" v-show="topToast">
              Everything was saved successfully
            </div>
          </transition>

          <transition name="fade">
            <div class="toast toast-bottom toast-dark" role="alert" v-show="bottomToast">
              Looks like something just happened
            </div>
          </transition>

          <div class="d-flex flex-column mt-4">
            <div class="toast toast-info toast-example" role="alert">
              Info
            </div>

            <div class="toast toast-success toast-example" role="alert">
              Success
            </div>
            <div class="toast toast-danger toast-example" role="alert">
              Danger
            </div>
            <div class="toast toast-warning toast-example" role="alert">
              Warning
            </div>
            <div class="toast toast-dark toast-example" role="alert">
              Dark
            </div>
            <div class="toast toast-light toast-example" role="alert">
              Light
            </div>
          </div>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>
            Show Code
          </div>
          <pre v-highlight><code class="language-html">

&lt;div class="toast toast-top toast-info" role="alert"&gt;
  Everything was saved successfully
&lt;/div&gt;

&lt;div class="toast toast-bottom toast-dark" role="alert"&gt;
  Looks like something just happened
&lt;/div&gt;
                </code></pre>
        </div>
        <p class="mt-4">
          <strong>Note!</strong> There is no current functionality for this functionality, so this is just a demonstration. A plugin or custom code will be
          required so markup may change depending on the chosen solution.
        </p>
      </div>
      <div class="sg-component__guide">
        <ul>
          <li>
            Toast are small notification text boxes that appears at either the top or bottom on the screen and dissapears after a specific amount of time.
          </li>
          <li>
            Uses the same colours as alerts. Example .toast-danger, .toast-info etc
          </li>
        </ul>
      </div>
    </div>

    <h3 id="disclaimer">Disclaimer Line</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <p class="disclaimer-line">
            <svg class="icon icon-warning">
              <use xlink:href="/icons/symbol-defs.svg#icon-warning"></use>
            </svg>
            Documents are not available for order on myLTSA Explorer
          </p>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>
            Show Code
          </div>
          <pre v-highlight><code class="language-html">

                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide">
        <ul>
          <li>
            Should be used for inline disclaimers and tips where a normal alert box would break the flow of the content
          </li>
        </ul>
      </div>
    </div>
  </component-frame>
</template>

<script>
export default {
  name: "feedback",
  data() {
    return {
      title: "Feedback",
      topToast: false,
      bottomToast: false,
    };
  },
  methods: {
    toggleTopToast: function($event) {
      var _this = this;

      this.topToast = true;

      setTimeout(
        function() {
          this.topToast = false;
        }.bind(this),
        3000
      );
    },
    toggleBottomToast: function($event) {
      var _this = this;

      this.bottomToast = true;

      setTimeout(
        function() {
          this.bottomToast = false;
        }.bind(this),
        3000
      );
    },
  },
};
</script>

<style scoped>
.toast-example {
  display: inline-block;
  position: static !important;
  margin-bottom: 0.5rem;
  transform: none !important;
  opacity: 1;
}
</style>
